body{
  background-color: #F8F8F8;
}
iframe{
  background: red;
  left: 9999999999px !important;
}
.launchApp{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color:#EEEFFF;
  z-index: 300;
  padding:10px 15px;
  box-sizing: border-box;
}
.launchApp-logo{
  width: 30px;
  height: 30px;
}
.launchApp-empty{
  height: 50px;
}
.link{
  color: #5D61FF;
  text-decoration: none;
}
.launchApp-txt{
  padding-left: 8px;
  width: 200px;
  font-size: 12px;
  font-family: SF UI Display;
  color: #555555;
  line-height: 16px;
  word-break: break-word;
}
.launchApp-txt span{
  font-weight: bold;
}
.download-btn,.popup-btn{
  width: 83px;
  height: 28px;
  background: #5D61FF;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
  color: white;
  font-size: 12px;
}
.popup-img{
  display: block;
  margin: 20px auto;
  width: 40px;
}
.popup-txt{
  text-align: center;
  font-weight: bold;
  margin: 0;
}
.popup-btn{
  margin: 20px auto;
}
.adm-modal-footer .adm-modal-button.modal-btn{
  padding: 5px 0;
  width: 59%;
  margin: 0 auto;
  font-size: 14px;
}
.textarea-border{
  position: relative;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}
/* .textarea-border::after{
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  border: 1px solid #d0d0d0;
  transform: scale(0.5);
  border-radius: 5px;
  left: -50%;
  top: -50%;
} */